import React, { Component } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios';
import { setSubscriptionsCancelled } from '../../../actions/subscriptionsActions'
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom"
import SetDomainName from '../../../components/plans/setDomainName'
import DeleteDomainName from '../../../components/plans/deleteDomainName'
import AddDNSRecord from '../../../components/plans/addDNSRecord'
import RemoveDNSRecord from '../../../components/plans/removeDNSRecord'

class DNS extends Component {
    state = {
        nameServers: [],
        domain: null,
        showSetDomainModal: false,
        showDeleteDomainModal: false,
        sslCertified: "false",
        domainType: null,
        dnsRecords: [],
        showAddRecord: false,
        removeDNSRecordObj: null,
        removeDnsRecord: false

    }
    componentDidMount() {
        if (this.props.subscriptions.detailedIndex !== null) {
            this.getSiteData()

        }


    }
    getSiteData = () => {

        let subid = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem
        Axios.post('/api/sites/get-site', { subId: subid }).then(req => {
            console.log(req.data)
            if (req.data.site) {
                this.setState({ site: req.data.site, nameServers: req.data.nameServers, domain: req.data.domain, sslCertified: req.data.sslCertified, domainType: req.data.domainType, dnsRecords: req.data.dnsRecords[0] ? req.data.dnsRecords : [] })
            } else {
                this.setState({ site: req.data.site, nameServers: [], domain: null })
            }
        })

    }
    setDomainName = () => {
        this.setState({ showSetDomainModal: true })
    }
    closeSetDomainNameModal = () => {
        this.getSiteData()
        this.setState({ showSetDomainModal: false })
    }
    deleteDomainName = () => {
        this.setState({ showDeleteDomainModal: true })

    }
    closeDeleteDomainNameModal = () => {
        this.getSiteData()
        this.setState({ showDeleteDomainModal: false })
    }
    closeAddDNSRecordModal = () => {
        this.getSiteData()
        this.setState({ showAddRecord: false })
    }
    closeRemoveDNSRecordModal = () => {
        this.getSiteData()
        this.setState({ removeDnsRecord: false, removeDNSRecordObj: null })
    }
    openConfirmDeleteRecord=(record)=>{
        this.setState({removeDNSRecordObj: record, removeDnsRecord: true})
    }
    render() {
        let darkmode = this.props.user.darkmode

        if (this.props.subscriptions.detailedIndex === null) {
            return <Redirect to='/my-hosting' />
        }
        let subscription = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex]

        let manageSiteAllowed = subscription.status != 'waiting' && subscription.status != 'expired'

        let nameservers = []
        this.state.nameServers.forEach((nameServer, i) => {
            nameservers.push(<p key={"nameserver" + i}>NameServer {i + 1}: {nameServer}</p>)
        })
        if (!this.state.nameServers[0] && this.state.domain) {
            nameservers.push(<u><p>Refresh the page to get nameserver details</p></u>)
        }
        let dnsRecordsHtml = []
        this.state.dnsRecords.forEach((record, i) => {
            dnsRecordsHtml.push(
                <div key={"dns_record" + i}>
                    <Row style={{ marginRight: "10px", paddingLeft: "10px" }}>
                        <Col>Type:</Col>
                        <Col>{record.type}</Col>
                        <Col>Value:</Col>
                        <Col>{record.value}</Col>
                        <Col>TTL:</Col>
                        <Col>{record.ttl_seconds}</Col>
                        <Col>                <Button onClick={()=>this.openConfirmDeleteRecord(record)} className='button-warning' style={{ marginBottom: '10px', width: "100px" }}>Delete</Button>
                        </Col>
                    </Row>
                </div>

            )
        })
        return (<div>
            {manageSiteAllowed ?
                <div>
                    <Card style={{ width: '80%', margin: 'auto', display: 'block', marginTop: '100px' }}>
                        <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                            DNS
                        </Card.Header>
                        <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ textAlign: 'center', paddingTop: "10px" }}>
                            <p>Domain: <a target="#" href={`https://${this.state.domain}`}>{this.state.domain}</a></p>
                            {!this.state.site && <Button onClick={() => this.setDomainName()} style={{ backgroundColor: '#343a40', marginBottom: '10px' }}>Set Domain Name</Button>
                            }
                            {this.state.site && <p>SSL Status: <FontAwesomeIcon size='1x' icon={this.state.sslCertified === "true" ? faLock : faUnlock} /> {this.state.sslCertified === "true" ? "Active" : "Pending"}</p>
                            }
                            {nameservers}
                            {this.state.site && <Button className='button-warning' onClick={() => this.deleteDomainName()} style={{ marginBottom: '10px', width: "80%" }}>Remove Domain Name</Button>}
                        </div>
                    </Card>
                    {this.state.domain && this.state.domainType != "own_external" &&
                        <Card style={{ width: '80%', margin: 'auto', display: 'block', marginTop: '20px' }}>
                            <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                                Manage DNS Records
                            </Card.Header>
                            <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ textAlign: 'center', paddingTop: "10px" }}>
                                <Button onClick={() => { this.setState({ showAddRecord: true }) }} className='button-primary' style={{ marginBottom: '10px', width: "50%" }}>Add Record</Button>
                                {dnsRecordsHtml}
                            </div>
                        </Card>}
                </div>
                :
                <div>
                    <Card style={{ width: '80%', margin: 'auto', display: 'block', marginTop: '20px' }}>
                        <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                            Manage Site
                        </Card.Header>
                        <Card.Text className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ textAlign: 'center', paddingTop: "10px", paddingBottom: "20px" }}>
                            <FontAwesomeIcon style={{ paddingBottom: '20px' }} size='4x' icon={faLock} />
                            <p>Your subscription status must be active to manage your site.</p>
                        </Card.Text>
                    </Card>
                </div>
            }
            <SetDomainName closeCancelModal={this.closeSetDomainNameModal} show={this.state.showSetDomainModal} />
            <DeleteDomainName closeDeleteDomainNameModal={this.closeDeleteDomainNameModal} show={this.state.showDeleteDomainModal} />
            <AddDNSRecord  domain={this.state.domain} closeAddDNSRecordModal={this.closeAddDNSRecordModal} show={this.state.showAddRecord} />
            <RemoveDNSRecord domain={this.state.domain} record={this.state.removeDNSRecordObj} closeRemoveDNSRecordModal={this.closeRemoveDNSRecordModal} show={this.state.removeDnsRecord} />
        </div>);
    }
}

function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setSubscriptionsCancelled: (index) => { dispatch(setSubscriptionsCancelled(index)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DNS);