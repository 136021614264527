import React, { Component } from 'react';
import { Spinner, DropdownButton, DropdownItem, Row, Col, Card, Form, Button } from 'react-bootstrap'
import axios from 'axios'
import { phone } from 'phone';
import { connect } from 'react-redux';

import Autocomplete from "react-google-autocomplete";

class RegistrationContact extends Component {
    state = {
        contactType: "PERSON",
        organisation: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneCode: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        country: "",
        stateProvince: "",
        city: "",
        zipPostal: "",
        privacyEnabled: true,
        valid: false,
        countries: {
            AC: "Ascension Island", AD: "Andorra", AE: "United Arab Emirates", AF: "Afghanistan", AG: "Antigua and Barbuda", AI: "Anguilla",
            AL: "Albania", AM: "Armenia", AN: "Netherlands Antilles", AO: "Angola", AQ: "Antarctica", AR: "Argentina", AS: "American Samoa", AT: "Austria",
            AU: "Australia", AW: "Aruba", AX: "Aland Islands", AZ: "Azerbaijan", BA: "Bosnia and Herzegovina", BB: "Barbados", BD: "Bangladesh", BE: "Belgium",
            BF: "Burkina Faso", BG: "Bulgaria", BH: "Bahrain", BI: "Burundi", BJ: "Benin", BL: "Saint Barthelem", BM: "Bermuda", BN: "Brunei", BO: "Bolivia",
            BQ: "Bonaire, Saint Eustatius and Saba", BR: "Brazil", BS: "Bahamas", BT: "Bhutan", BV: "Bouvet Island", BW: "Botswana", BY: "Belarus", BZ: "Belize",
            CA: "Canada", CC: "Cocos Islands", CD: "Democratic Republic of the Congo", CF: "Central African Republic", CG: "Republic of the Congo", CH: "Switzerland",
            CI: "Ivory Coast", CK: "Cook Islands", CL: "Chile", CM: "Cameroon", CN: "China", CO: "Colombia", CR: "Costa Rica", CU: "Cuba", CV: "Cape Verde",
            CW: "Curacao", CX: "Christmas Island", CY: "Cyprus", CZ: "Czech Republic", DE: "Germany", DJ: "Djibouti", DK: "Denmark", DM: "Dominica", DO: "Dominican Republic",
            DZ: "Algeria", EC: "Ecuador", EE: "Estonia", EG: "Egypt", EH: "Western Sahara", ER: "Eritrea", ES: "Spain", ET: "Ethiopia", FI: "Finland", FJ: "Fiji",
            FK: "Falkland Islands", FM: "Micronesia", FO: "Faroe Islands", FR: "France", GA: "Gabon", GB: "United Kingdom", GD: "Grenada", GE: "Georgia", GF: "French Guiana",
            GG: "Guernsey", GH: "Ghana", GI: "Gibraltar", GL: "Greenland", GM: "Gambia", GN: "Guinea", GP: "Guadeloupe", GQ: "Equatorial Guinea", GR: "Greece",
            GS: "South Georgia and the South Sandwich Islands", GT: "Guatemala", GU: "Guam", GW: "Guinea-Bissau", GY: "Guyana", HK: "Hong Kong",
            HM: "Heard Island and McDonald Islands", HN: "Honduras", HR: "Croatia", HT: "Haiti", HU: "Hungary", ID: "Indonesia", IE: "Ireland", IL: "Israel",
            IM: "Isle of Man", IN: "India", IO: "British Indian Ocean Territory", IQ: "Iraq", IR: "Iran", IS: "Iceland", IT: "Italy", JE: "Jersey", JM: "Jamaica",
            JO: "Jordan", JP: "Japan", KE: "Kenya", KG: "Kyrgyzstan", KH: "Cambodia", KI: "Kiribati", KM: "Comoros", KN: "Saint Kitts and Nevis", KP: "North Korea",
            KR: "South Korea", KW: "Kuwait", KY: "Cayman Islands", KZ: "Kazakhstan", LA: "Laos", LB: "Lebanon", LC: "Saint Lucia", LI: "Liechtenstein", LK: "Sri Lanka",
            LR: "Liberia", LS: "Lesotho", LT: "Lithuania", LU: "Luxembourg", LV: "Latvia", LY: "Libya", MA: "Morocco", MC: "Monaco", MD: "Moldova", ME: "Montenegro",
            MF: "Saint Martin", MG: "Madagascar", MH: "Marshall Islands", MK: "North Macedonia", ML: "Mali", MM: "Myanmar", MN: "Mongolia", MO: "Macau",
            MP: "Northern Mariana Islands", MQ: "Martinique", MR: "Mauritania", MS: "Montserrat", MT: "Malta", MU: "Mauritius", MV: "Maldives", MW: "Malawi",
            MX: "Mexico", MY: "Malaysia", MZ: "Mozambique", NA: "Namibia", NC: "New Caledonia", NE: "Niger", NF: "Norfolk Island", NG: "Nigeria", NI: "Nicaragua",
            NL: "Netherlands", NO: "Norway", NP: "Nepal", NR: "Nauru", NU: "Niue", NZ: "New Zealand", OM: "Oman", PA: "Panama", PE: "Peru", PF: "French Polynesia",
            PG: "Papua New Guinea", PH: "Philippines", PK: "Pakistan", PL: "Poland", PM: "Saint Pierre and Miquelon", PN: "Pitcairn", PR: "Puerto Rico", PS: "Palestine",
            PT: "Portugal", PW: "Palau", PY: "Paraguay", QA: "Qatar", RE: "Réunion", RO: "Romania", RS: "Serbia", RU: "Russia", RW: "Rwanda", SA: "Saudi Arabia",
            SB: "Solomon Islands", SC: "Seychelles", SD: "Sudan", SE: "Sweden", SG: "Singapore", SH: "Saint Helena", SI: "Slovenia", SJ: "Svalbard and Jan Mayen",
            SK: "Slovakia", SL: "Sierra Leone", SM: "San Marino", SN: "Senegal", SO: "Somalia", SR: "Suriname", SS: "South Sudan", ST: "São Tomé and Príncipe",
            SV: "El Salvador", SX: "Sint Maarten", SY: "Syria", SZ: "Eswatini", TC: "Turks and Caicos Islands", TD: "Chad", TF: "French Southern and Antarctic Lands",
            TG: "Togo", TH: "Thailand", TJ: "Tajikistan", TK: "Tokelau", TL: "Timor-Leste", TM: "Turkmenistan", TN: "Tunisia", TO: "Tonga", TP: "East Timor",
            TR: "Turkey", TT: "Trinidad and Tobago", TV: "Tuvalu", TW: "Taiwan", TZ: "Tanzania", UA: "Ukraine", UG: "Uganda", US: "United States", UY: "Uruguay",
            UZ: "Uzbekistan", VA: "Vatican City", VC: "Saint Vincent and the Grenadines", VE: "Venezuela", VG: "British Virgin Islands", VI: "U.S. Virgin Islands",
            VN: "Vietnam", VU: "Vanuatu", WF: "Wallis and Futuna", WS: "Samoa", YE: "Yemen", YT: "Mayotte", ZA: "South Africa", ZM: "Zambia", ZW: "Zimbabwe"
        },
        countriesNoState: ["GB", "PK"],
        emailInvalidText: "",
        codeInvalidText: "",
        phoneInvalidText: "",
        zipInvalidText: ""

    }
    componentDidMount() {
        this.props.basket.forEach(item => {
            let pee = ""

            if (item.active) {
                let newDeets = {

                }
                if (this.props.type == "registrationContact") {
                    pee = "RegistrationContact"
                    newDeets.privacyEnabled = item.contactsAndPrivacy.PrivacyProtectRegistrationContact
                    newDeets.valid = item.registrationContactValid

                }
                if (this.props.type == "techContact") {
                    pee = "TechContact"
                    newDeets.privacyEnabled = item.contactsAndPrivacy.PrivacyProtectTechContact
                    newDeets.valid = item.techContactValid

                }
                if (this.props.type == "adminContact") {
                    pee = "AdminContact"
                    newDeets.privacyEnabled = item.contactsAndPrivacy.PrivacyProtectAdminContact
                    newDeets.valid = item.adminContactValid

                }
                if (!item.contactsAndPrivacy[pee]) {
                    return
                }

                if (item.contactsAndPrivacy[pee].FirstName) {
                    newDeets.firstName = item.contactsAndPrivacy[pee].FirstName
                }
                if (item.contactsAndPrivacy[pee].LastName) {
                    newDeets.lastName = item.contactsAndPrivacy[pee].LastName
                }
                if (item.contactsAndPrivacy[pee].ContactType) {
                    newDeets.contactType = item.contactsAndPrivacy[pee].ContactType
                }
                if (item.contactsAndPrivacy[pee].OrganizationName) {
                    newDeets.organisation = item.contactsAndPrivacy[pee].OrganizationName
                }
                if (item.contactsAndPrivacy[pee].AddressLine1) {
                    newDeets.addressLine1 = item.contactsAndPrivacy[pee].AddressLine1
                }
                if (item.contactsAndPrivacy[pee].AddressLine2) {
                    newDeets.addressLine2 = item.contactsAndPrivacy[pee].AddressLine2
                }
                if (item.contactsAndPrivacy[pee].City) {
                    newDeets.city = item.contactsAndPrivacy[pee].City
                }
                if (item.contactsAndPrivacy[pee].State) {
                    newDeets.stateProvince = item.contactsAndPrivacy[pee].State
                }
                if (item.contactsAndPrivacy[pee].CountryCode) {

                    newDeets.country = item.contactsAndPrivacy[pee].CountryCode
                }
                if (item.contactsAndPrivacy[pee].ZipCode) {
                    newDeets.zipPostal = item.contactsAndPrivacy[pee].ZipCode
                }
                if (item.contactsAndPrivacy[pee].PhoneNumber) {
                    let strip = item.contactsAndPrivacy[pee].PhoneNumber.split("+")
                    let arr = strip[1].split(".")
                    newDeets.phoneCode = arr[0]
                    newDeets.phoneNumber = arr[1]

                }
                if (item.contactsAndPrivacy[pee].Email) {
                    newDeets.email = item.contactsAndPrivacy[pee].Email
                }
                this.setState({ ...newDeets }, () => {
                    this.props.contactUpdated(this.props.type, this.state)

                })

            }
        })
    }
    updateValue = (key, value) => {
        if (key == "zipPostal") {
            value = value.toUpperCase()
        }
        if (key == "country") {
            if (this.state.countriesNoState.includes(value)) {
                this.setState({ stateProvince: "" })
            }
        }
        if (key == "stateProvince") {
            if (this.state.countriesNoState.includes(this.state.country)) {
                value = ""
            }
        }
        if (key == "phoneNumber" || key == "phoneCode"){
            if (isNaN(value) || value === " " || value.includes(" ") ) {
                return
            }
        }
        this.setState({ [key]: value }, () => {
            let valid = true
            // case "contactType":
            if (!["PERSON", "COMPANY", "ASSOCIATION", "PUBLIC_BODY", "RESELLER"].includes(this.state.contactType)) {
                valid = false
            }

            // case "organisation":
            if (this.state.contactType != "PERSON") {
                if (!this.organisationValid()) {
                    valid = false
                }
            }

            // case "firstName":
            if (!this.firstNameValid()) {
                valid = false
            }

            // case "lastName":
            if (!this.lastNameValid()) {
                valid = false
            }

            // case "email":
            if (!this.emailValid()) {
                valid = false
            }
            // case "phoneCode":
            if (!this.phoneCodeValid()) {
                valid = false
            }
            // case "phoneNumber":
            if (!this.phoneNumberValid()) {
                valid = false
            }

            // case "addressLine1":
            if (!this.addressLine1Valid()) {
                valid = false
            }
            // case "addressLine2":
            if (!this.addressLine2Valid()) {
                valid = false
            }
            // case "country":
            if (!this.countryValid()) {
                valid = false
            }
            // case "stateProvince":
            if (!this.stateProviceValid()) {
                valid = false
            }
            // case "city":
            if (!this.cityValid()) {
                valid = false
            }
            // case "zipPostal":
            if (!this.zipPostalValid()) {
                valid = false
            }



            this.setState({ valid: valid }, () => {
                this.props.contactUpdated(this.props.type, this.state, this.state.valid)
            })
        })
    }
    organisationValid = () => {
        if (this.state.organisation.length == 0 || this.state.organisation.length > 255) {
            return false
        }
        return true
    }
    countryValid = () => {
        if (!this.state.countries[this.state.country]) {
            return false
        }
        return true
    }

    addressLine1Valid = () => {
        if (this.state.addressLine1.length == 0 || this.state.addressLine1.length > 255) {
            return false
        }
        return true
    }
    cityValid = () => {
        if (this.state.city.length > 255) {
            return false
        }
        return true
    }
    zipPostalValid = () => {
        if (this.state.zipPostal.length == 0 || this.state.zipPostal.length > 255) {
            let text = "Zip / Postal Required"
            if (this.state.zipInvalidText != text) {
                this.setState({ zipInvalidText: text })
            }
            return false
        }
        if (this.state.country == "GB") {
            const regex = /^[A-Z]+\d+\s\d+[A-Z]+$/;
            if (!regex.test(this.state.zipPostal)) {
                let text = "Zip / Postal Required in correct format... e.g. AB12 3CD"
                if (this.state.zipInvalidText != text) {
                    this.setState({ zipInvalidText: text })
                }
                return false
            }

        }
        return true
    }
    addressLine2Valid = () => {
        if (this.state.addressLine1.length > 255) {
            return false
        }
        return true
    }
    stateProviceValid = () => {
        if (this.state.stateProvince.length > 255) {
            return false
        }
        if (this.state.countriesNoState.includes(this.state.country)) {
            if (this.state.stateProvince != "") {
                return false
            }
        }
        return true
    }
    firstNameValid = () => {
        if (this.state.firstName.length == 0 || this.state.firstName.length > 255) {
            return false
        }
        return true
    }
    lastNameValid = () => {
        if (this.state.lastName.length == 0 || this.state.lastName.length > 255) {
            return false
        }
        return true
    }
    emailValid = () => {
        const regexEmailLike = /^[^@]+@[^@]+$/;
        if (this.state.email.length == 0) {
            if (this.state.emailInvalidText != "Email Required") {
                this.setState({ emailInvalidText: "Email Required" })
            }
            return false
        }
        if (this.state.email.length > 255) {
            if (this.state.emailInvalidText != "Email too long...") {

                this.setState({ emailInvalidText: "Email too long..." })
            }
            return false
        }
        if (regexEmailLike.test(this.state.email)) {
            return true
        } else {
            if (this.state.emailInvalidText != "Email Not Valid. E.g. email@domain.com") {

                this.setState({ emailInvalidText: "Email Not Valid. E.g. email@domain.com" })
            }
            return false
        }
    }
    phoneCodeValid = () => {
        if (String(this.state.phoneCode).length > 4) {
            let text = "Country code not valid"
            if (this.state.codeInvalidText != text) {
                this.setState({ codeInvalidText: text })
            }
            return false
        }
        if (isNaN(this.state.phoneCode) || this.state.phoneCode == "") {
            let text = "Country code required"
            if (this.state.codeInvalidText != text) {
                this.setState({ codeInvalidText: text })
            }
            return false
        }
        return true
    }
    phoneNumberValid = () => {

        if (!phone("+" + this.state.phoneCode + this.state.phoneNumber).isValid) {
            let text = "Valid phone number required.."
            if (this.state.phoneInvalidText != text) {
                this.setState({ phoneInvalidText: text })
            }
            return false
        }
        if (isNaN(this.state.phoneNumber) || this.state.phoneNumber == "" || String(this.state.phoneNumber).length < 4) {
            let text = "Phone number required.."
            if (this.state.phoneInvalidText != text) {
                this.setState({ phoneInvalidText: text })
            }
            return false
        }
        if (String(this.state.phoneNumber).length > 26) {
            let text = "Phone number too long.."
            if (this.state.phoneInvalidText != text) {
                this.setState({ phoneInvalidText: text })
            }
            return false
        }
        return true
    }
    handlePrivacyClicked = () => {
        // why does this not update in db for basket update
        this.setState({ privacyEnabled: !this.state.privacyEnabled }, () => {
            this.props.contactUpdated(this.props.type, this.state)

        })
    }
    confirmAddr = () => {
        axios.post("https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyDbCs8VDGnyiUDHYHlvDpOY2WgkXzCk9nE", {}).then(res => {
        })
    }

    render() {


        let codeHtml = []
        Object.entries(this.state.countries).forEach(entry => {
            const [code, country] = entry;
            codeHtml.push(
                <DropdownItem onClick={() => this.updateValue("country", code)}>{country}</DropdownItem>)

        });
        let showAddress = this.state.addressLine1 || this.state.addressLine2 || this.state.city || this.state.country || this.state.zipPostal

        return (
            <div>

                <Row>
                    {
                        (!["PERSON", "COMPANY", "ASSOCIATION", "PUBLIC_BODY", "RESELLER"].includes(this.state.contactType))
                        &&

                        <Col>
                            <p style={{ color: "red" }}>Required</p>
                        </Col>
                    }
                    <Col>
                        Contact Type:
                    </Col>
                    <Col>
                        <DropdownButton align="end" title={this.state.contactType} variant="primary">
                            <DropdownItem onClick={() => this.updateValue("contactType", "PERSON")}>person</DropdownItem>
                            <DropdownItem onClick={() => this.updateValue("contactType", "COMPANY")}>company</DropdownItem>
                            <DropdownItem onClick={() => this.updateValue("contactType", "ASSOCIATION")}>association</DropdownItem>
                            <DropdownItem onClick={() => this.updateValue("contactType", "PUBLIC_BODY")}>public body</DropdownItem>
                            <DropdownItem onClick={() => this.updateValue("contactType", "RESELLER")}>reseller</DropdownItem>
                        </DropdownButton>
                    </Col>
                </Row>
                {this.state.contactType != "PERSON" &&
                    <Row>
                        {
                            (!this.organisationValid())
                            &&

                            <Col>
                                <p style={{ color: "red" }}>Required</p>
                            </Col>
                        }
                        <Col>
                            Organisation
                        </Col>
                        <Col>
                            <input style={{ width: '100%' }} type='text' value={this.state.organisation} onChange={(e) => { this.updateValue("organisation", e.target.value) }} placeholder='Organisation' />
                        </Col>
                    </Row>
                }

                <Row>
                    {
                        (!this.firstNameValid())
                        &&

                        <Col>
                            <p style={{ color: "red" }}>Required</p>
                        </Col>
                    }
                    <Col>
                        First Name
                    </Col>
                    <Col>
                        <input style={{ width: '100%' }} type='text' value={this.state.firstName} onChange={(e) => this.updateValue("firstName", e.target.value)} placeholder='First Name' />
                    </Col>
                </Row>
                <Row>
                    {
                        (!this.lastNameValid())
                        &&

                        <Col>
                            <p style={{ color: "red" }}>Required</p>
                        </Col>
                    }
                    <Col>
                        Last Name
                    </Col>
                    <Col>
                        <input style={{ width: '100%' }} type='text' value={this.state.lastName} onChange={(e) => this.updateValue("lastName", e.target.value)} placeholder='Last Name' />
                    </Col>
                </Row>
                <Row>
                    {
                        (!this.emailValid())
                        &&

                        <Col>
                            <p style={{ color: "red" }}>{this.state.emailInvalidText}</p>
                        </Col>
                    }
                    <Col>
                        Email
                    </Col>
                    <Col>
                        <input style={{ width: '100%' }} type='text' value={this.state.email} onChange={(e) => this.updateValue("email", e.target.value)} placeholder='Email' />
                    </Col>
                </Row>
                <Row>
                    {
                        (!this.phoneCodeValid())
                        &&

                        <Col>
                            <p style={{ color: "red" }}>{this.state.codeInvalidText}</p>
                        </Col>
                    }
                    {
                        (!this.phoneNumberValid())
                        &&

                        <Col>
                            <p style={{ color: "red" }}>{this.state.phoneInvalidText}</p>
                        </Col>
                    }
                    <Col>
                        Contact Number
                    </Col>
                    <Col style={{ width: "10px", textAlign: "right", align: "right", float: "right", marginRight: "-10px" }}>
                        <p style={{ width: "10px", textAlign: "right", align: "right", float: "right", fontSize: "30px" }}>
                            +
                        </p>
                    </Col>
                    <Col xs="1" style={{ marginRight: "5px", paddingLeft: "10px", marginLeft: "0px" }}>

                        <input style={{ width: '60px', float: "left", marginRight: "5px", paddingLeft: "5px", marginLeft: "15px" }} type='text' value={this.state.phoneCode} onChange={(e) => this.updateValue("phoneCode", e.target.value)} placeholder='44' />
                    </Col>
                    <Col xs="3" style={{ width: '100%', marginLeft: "5px", paddingLeft: "20px" }}>
                        <input style={{ width: '100%' }} type='text' value={this.state.phoneNumber} onChange={(e) => this.updateValue("phoneNumber", e.target.value)} placeholder='1234567891' />
                    </Col>

                </Row>
                <Row>
                    <Col><h4>Address</h4></Col>
                </Row>
                <Row>
                    <Col>
                        <Autocomplete
                            apiKey={"AIzaSyC6caFtibNtwMpnVd49tfKOX30NTXJqvM0"}
                            style={{ width: "100%" }}
                            onPlaceSelected={(place) => {
                                this.setState({ zipPostal: "", addressLine1: "", city: "", stateProvince: "", addressLine2: "" })
                                place.address_components.forEach(part => {
                                    if (part.types.includes("postal_code")) {
                                        this.updateValue("zipPostal", part.long_name)
                                    }
                                    if (part.types.includes("street_number")) {
                                        this.updateValue("addressLine1", part.long_name)
                                    }
                                    if (part.types.includes("route")) {
                                        this.updateValue("addressLine1", this.state.addressLine1 + " " + part.long_name)
                                    }
                                    if (part.types.includes("postal_town")) {
                                        this.updateValue("city", part.long_name)
                                    }
                                    if (part.types.includes("administrative_area_level_1")) {
                                        this.updateValue("stateProvince", part.long_name)
                                    }
                                    if (part.types.includes("administrative_area_level_2")) {
                                        this.updateValue("addressLine2", part.long_name)
                                    }
                                    if (part.types.includes("country")) {
                                        this.updateValue("country", part.short_name)
                                    }
                                })
                            }}
                            options={{
                                types: ["geocode"],
                            }}
                        />
                    </Col>

                </Row>
                {showAddress ?
                    <div>
                        <Row>
                            {
                                (!this.addressLine1Valid())
                                &&

                                <Col>
                                    <p style={{ color: "red" }}>Required</p>
                                </Col>
                            }
                            <Col>
                                Address Line 1
                            </Col>
                            <Col>
                                <input style={{ width: '100%' }} type='text' value={this.state.addressLine1} onChange={(e) => this.updateValue("addressLine1", e.target.value)} placeholder='Address Line 1' />
                            </Col>
                        </Row>
                        <Row>
                            {
                                (!this.addressLine2Valid())
                                &&

                                <Col>
                                    <p style={{ color: "red" }}>Required</p>
                                </Col>
                            }
                            <Col>
                                Address Line 2
                            </Col>
                            <Col>
                                <input style={{ width: '100%' }} type='text' value={this.state.addressLine2} onChange={(e) => this.updateValue("addressLine2", e.target.value)} placeholder='Address Line 2' />
                            </Col>
                        </Row>
                        <Row>
                            {
                                (!this.countryValid())
                                &&

                                <Col>
                                    <p style={{ color: "red" }}>Required</p>
                                </Col>
                            }
                            <Col>
                                Country
                            </Col>
                            <Col>

                                <DropdownButton align="end" title={this.state.country ? this.state.countries[this.state.country] : "country"} variant="primary">
                                    <div style={{ height: '200px', overflow: 'auto', maxWidth: "80vw" }}>

                                        {codeHtml}                        </div>

                                </DropdownButton>
                            </Col>
                        </Row>
                        {!this.state.countriesNoState.includes(this.state.country) &&
                            <Row>
                                {
                                    (!this.stateProviceValid())
                                    &&

                                    <Col>
                                        <p style={{ color: "red" }}>Required</p>
                                    </Col>
                                }
                                <Col>
                                    State / Province
                                </Col>
                                <Col>
                                    <input style={{ width: '100%' }} type='text' value={this.state.stateProvince} onChange={(e) => this.updateValue("stateProvince", e.target.value)} placeholder='State / Province' />
                                </Col>
                            </Row>
                        }
                        <Row>
                            {
                                (!this.cityValid())
                                &&

                                <Col>
                                    <p style={{ color: "red" }}>Required</p>
                                </Col>
                            }
                            <Col>
                                city
                            </Col>
                            <Col>
                                <input style={{ width: '100%' }} type='text' value={this.state.city} onChange={(e) => this.updateValue("city", e.target.value)} placeholder='City' />
                            </Col>
                        </Row>
                        <Row>
                            {
                                (!this.zipPostalValid())
                                &&

                                <Col>
                                    <p style={{ color: "red" }}>{this.state.zipInvalidText}</p>
                                </Col>
                            }
                            <Col>
                                Zip / Postal
                            </Col>
                            <Col>
                                <input style={{ width: '100%' }} type='text' value={this.state.zipPostal} onChange={(e) => this.updateValue("zipPostal", e.target.value)} placeholder='Zip / Postal' />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Enable Domain Privacy (This may not be available for all domains):
                            </Col>
                            <Col>
                                <Form.Control checked={this.state.privacyEnabled} onChange={() => this.handlePrivacyClicked()} type="checkbox" />

                            </Col>
                        </Row>

                    </div> :
                    <div>
                        <Col>
                            <p style={{ color: "red" }}>Address Required</p>
                        </Col>
                    </div>
                }


            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        basket: state.basket
    }
}
function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationContact);
